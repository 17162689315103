import {DotLottiePlayer, PlayerEvents} from '@dotlottie/react-player';
import {checkAutoplay, cn} from "app/helpers";
import {forwardRef, useCallback, useEffect, useId, useImperativeHandle, useRef, useState} from "react";
import Controls from "components/media/Controls/Controls";
import {useDispatch, useSelector} from "react-redux";
import {
  getActiveMedia,
  getCanPlay,
  getMediaPlaying,
  getMediaUserSettings,
  setActiveMedia,
} from "features/media/mediaSlice";
import Captions from 'components/media/Controls/Captions';

const LottieController = class {
  constructor(lottie) {
    this.lottie = lottie;
  }

  play() {
    return this.lottie.play();
  }

  pause() {
    return this.lottie.pause();
  }

  seek(timestamp = 0) {
    const animationItem = this.lottie?.getAnimationInstance();
    if (animationItem) {
      return this.lottie.seek(timestamp * animationItem.frameRate + 1);
    }
    else {
      return this.lottie.seek(0);
    }
  }

  mute() {
    const audioController = this.lottie?.getAnimationInstance()?.audioController;
    if (audioController) {
      return audioController.mute();
    }
  }

  unmute() {
    const audioController = this.lottie?.getAnimationInstance()?.audioController;
    if (audioController) {
      return audioController.unmute();
    }
  }

  setVolume(volume) {
    const audioController = this.lottie?.getAnimationInstance()?.audioController;
    if (audioController) {
      audioController.setVolume(volume);
    }
  }

  get muted() {
    const audioController = this.lottie?.getAnimationInstance()?.audioController;
    return audioController?._isMuted || false;
  }

  get volume() {
    const audioController = this.lottie?.getAnimationInstance()?.audioController;
    return audioController?.getVolume();
  }

  get paused() {
    return this.lottie?.getState().currentState !== 'playing';
  }

  get ended() {
    return this.lottie?.getState().currentState === 'completed';
  }
}

const _updateVolume = function () {
  this.audios.forEach(audio => {
    const newVolume = this._volume * (this._isMuted ? 0 : 1);
    if (audio.audio._howl.volume() !== newVolume) {
      audio.audio._howl.fade(audio.audio._howl.volume(), newVolume, 50);
    }
  });
};

const LottiePlayer = forwardRef(function LottiePlayer({
                                                        src,
                                                        controls = false,
                                                        renderer = 'canvas',
                                                        autoPlay = true,
                                                        muted = false,
                                                        volume = 1,
                                                        loop = false,
                                                        onComplete,
                                                        onCompleteOffset = 0,
                                                        onError,
                                                        restart = true,
                                                        isMain = false,
                                                        captions
                                                      }, ref) {
  const classes = cn('lottie-player');
  const dispatch = useDispatch();
  const playerId = useId();
  const activeMedia = useSelector(getActiveMedia);
  const isMediaPlaying = useSelector(getMediaPlaying);
  const [complete, setComplete] = useState(false);
  const [initFrame, setInitFrame] = useState(0);
  const lottieRef = useRef();
  const [lottieController, setLottieController] = useState(null);
  const [ratio, setRatio] = useState();

  const userSettings = useSelector(state => getMediaUserSettings(state));
  autoPlay = autoPlay && (!controls || userSettings.autoPlay);
  const canPlay = useSelector(state => getCanPlay(state, playerId, {
    isMain,
    autoPlay,
  }));
  const [state, setState] = useState({
    isPlaying: false,
    isEnded: false,
    isMuted: userSettings.muted,
    currentTime: 0,
    duration: 0,
    volume: userSettings.volume,
  });

  if (lottieRef.current) {
    const audioController = lottieRef.current.getAnimationInstance()?.audioController;
    if (audioController) {
      // There is a bug in DotLottie player. @todo find better fix
      audioController._updateVolume = _updateVolume.bind(audioController);
    }
  }

  useImperativeHandle(ref, () => {
    return lottieController;
  }, [lottieController]);

  useEffect(() => {
    const lottie = lottieRef.current;
    const lottieController = !lottie ? null : new LottieController(lottie);
    setLottieController(lottieController);
  }, [lottieRef.current]);

  useEffect(() => {
    return () => {
      if (isMain) {
        dispatch(setActiveMedia({
          playerId,
          isPlaying: false,
        }));
      }
    }
  }, [dispatch, isMain, playerId]);

  useEffect(() => {
    const lottie = lottieRef.current;
    if (isMain && lottie?.getState().currentState === 'playing' && activeMedia !== playerId && isMediaPlaying) {
      // pause.
      lottie.pause();
    }
  }, [isMain, playerId, activeMedia, isMediaPlaying, dispatch]);

  const handleReady = useCallback(() => {
    const lottie = lottieRef.current;
    const animationItem = lottie?.getAnimationInstance();
    const audioController = animationItem?.audioController;
    const animationData = animationItem?.animationData;

    if (animationData?.w && animationData?.h) {
      setRatio(animationData.w / animationData.h);
    }

    if (audioController) {
      // There is a bug in DotLottie player. @todo find better fix
      audioController._updateVolume = _updateVolume.bind(audioController);
    }

    audioController._isMuted = muted;
    audioController.setVolume(volume);
    audioController.audios.forEach(audio => {
      const sound = audio.audio._howl;
      sound.on('play', () => {
        // Sync audio with animation.
        const initTime = ((animationItem.currentFrame - (audio?.data?.st || 0)) + 1) / animationItem.frameRate;
        sound.seek(initTime > 0 ? initTime : 0);
      });
    });

    if (autoPlay && canPlay) {
      if (!muted) {
        checkAutoplay().then(() => {
          // Autoplay with sound is allowed.
          lottie.getState().currentState !== 'loading' && lottie.play();
        }).catch(() => {
          // Autoplay with sound is not allowed.
          audioController._isMuted = true;
          lottie.play();
        });
      } else {
        lottie.play();
      }
    }
  }, [autoPlay, canPlay, muted, volume]);

  const handlePlay = useCallback(() => {
    const lottie = lottieRef.current;
    const animationItem = lottie?.getAnimationInstance();

    if (playerId && isMain) {
      dispatch(setActiveMedia({
        playerId,
        isPlaying: true,
      }));
    }

    if (animationItem) {
      const initFrame = lottie.initFrame || 0;
      lottie.seek(initFrame);
    }
  }, [dispatch, isMain, playerId]);

  const handlePause = useCallback(() => {
    const lottie = lottieRef.current;
    const animationItem = lottie?.getAnimationInstance();

    if (isMain) {
      dispatch(setActiveMedia({
        playerId,
        isPlaying: false,
      }));
    }
    setInitFrame(animationItem?.currentFrame || 0);
  }, [dispatch, isMain, playerId]);

  const handleComplete = useCallback(() => {
    if (isMain) {
      dispatch(setActiveMedia({
        playerId,
        isPlaying: false,
      }));
    }
    setInitFrame(0);
    onCompleteOffset === 0 && setComplete(true);
  }, [dispatch, isMain, onCompleteOffset, playerId]);

  const handleEnterFrame = useCallback(() => {
    const animationItem = lottieRef.current?.getAnimationInstance();

    if (animationItem) {
      const currentTime = animationItem.currentFrame / animationItem.frameRate * 1000;
      const totalTime = animationItem.totalFrames / animationItem.frameRate * 1000;

      if (!complete && onCompleteOffset > 0 && totalTime - currentTime < onCompleteOffset) {
        setComplete(true);
      }
    }
  }, [complete, onCompleteOffset]);

  const handleState = useCallback(() => {
    const lottie = lottieRef.current;
    const animationItem = lottie?.getAnimationInstance();
    const audioController = animationItem?.audioController;

    if (animationItem) {
      setState({
        isPlaying: lottie.getState().currentState === 'playing',
        isEnded: lottie.getState().currentState === 'completed',
        isMuted: audioController?._isMuted,
        currentTime: (animationItem.currentFrame) / animationItem.frameRate,
        duration: (animationItem.totalFrames - 1) / animationItem.frameRate,
        volume: audioController.getVolume(),
      });
    }
  }, []);

  const handleEvents = useCallback((e, data) => {
    const lottie = lottieRef.current;

    if (lottie) {
      switch (e) {
        case PlayerEvents.Ready:
          handleReady();
          break;
        case PlayerEvents.Frame:
          handleEnterFrame();
          break;
        case PlayerEvents.Complete:
          handleComplete();
          break;
        case PlayerEvents.Play:
          handlePlay();
          break;
        case PlayerEvents.Freeze:
        case PlayerEvents.Pause:
          handlePause();
          break;
      }

      handleState();
    }
  }, [handleComplete, handleEnterFrame, handlePause, handlePlay, handleReady, handleState]);

  const handleControlsPlay = useCallback(() => {
    const lottie = lottieRef.current;

    if (lottie && lottie.getState().currentState !== 'loading') {
      lottie.play();
    }
  }, []);

  const handleControlsPause = useCallback(() => {
    const lottie = lottieRef.current;

    if (lottie) {
      lottie.pause();
    }
  }, []);

  const handleProgressChange = useCallback((timestamp) => {
    const lottie = lottieRef.current;
    const animationItem = lottie?.getAnimationInstance();

    if (animationItem) {
      const frame = animationItem.frameRate * timestamp;
      setInitFrame(frame);
      lottie.seek(frame);
    }
  }, []);

  const handleVolumeChange = useCallback((volume, isMuted) => {
    const lottie = lottieRef.current;
    const audioController = lottie?.getAnimationInstance()?.audioController;

    if (audioController) {
      audioController.setVolume(volume);
      isMuted ? audioController.mute() : audioController.unmute();
    }
  }, []);

  const handleClick = useCallback(() => {
    if (controls) {
      const lottie = lottieRef.current;
      if (lottie) {
        lottie.getState().currentState === 'playing' ? lottie.pause() : lottie.play();
      }
    }
  }, [controls]);

  useEffect(() => {
    const lottie = lottieRef.current;
    if (lottie) {
      if (!restart) {
        setInitFrame(lottie.getAnimationInstance()?.currentFrame || 0);
      } else {
        setInitFrame(0);
      }
    }
  }, [restart, src]);

  useEffect(() => {
    const lottie = lottieRef.current;
    if (lottie) {
      lottie.initFrame = initFrame;

      if (canPlay) {
        if (lottie.getState().currentState === 'ready' && autoPlay) {
          lottie.play();
        }
        else if (lottie.getState().currentState === 'playing' && !autoPlay) {
          lottie.pause();
        }
      }
      else if (lottie.getState().currentState === 'playing' && autoPlay) {
        lottie.pause();
      }
    }
  }, [initFrame, autoPlay, canPlay]);

  useEffect(() => {
    if (lottieController) {
      if (!muted) {
        lottieController.setVolume(volume);
        lottieController.unmute();
      }
      else {
        lottieController.mute();
      }
      handleState();
    }
  }, [handleState, lottieController, muted, volume]);

  useEffect(() => {
    if (complete) {
      onComplete && onComplete();
      setComplete(false);
    }
  }, [complete, onComplete]);

  useEffect(() => {
    const lottie = lottieRef.current;

    if (lottie && ratio && lottie.getState().currentState !== 'loading') {
      // Resize sometimes fail with "TypeError: this.audio.volume is not a function" error.
      // It maybe depends on played file.
      // @todo Investigate the issue cause.
      try {
        lottie.resize();
      }
      catch (e) {
        // Do nothing.
      }
    }
  }, [ratio]);

  return src && <div className={classes()}>
    <DotLottiePlayer style={{
                       aspectRatio: ratio || 'initial',
                     }}
                     lottieRef={lottieRef}
                     src={src}
                     loop={loop}
                     autoplay={false}
                     onEvent={handleEvents}
                     renderer={renderer}
                     rendererSettings={{
                       preserveAspectRatio: "xMidYMid slice",
                     }}
                     onClick={handleClick}
    />
    <div className={'player-controls-wrapper'}>
      {captions && userSettings.captions && <Captions url={captions.url} currentTime={state.currentTime}/>}
      {controls && <Controls onPlay={handleControlsPlay} onPause={handleControlsPause} onProgressChange={handleProgressChange} onVolumeChange={handleVolumeChange} {...state}/>}
    </div>
  </div>
});
export default LottiePlayer;
