import { useEffect, useState } from 'react';
import { CaptionsRenderer, parseResponse } from 'media-captions';

export default function Captions({media, url, currentTime}) {
  const [timestamp, setTimestamp] = useState();
  const [container, setContainer] = useState(null);
  const [renderer, setRenderer] = useState(null);

  useEffect(() => {
    if (container) {
      if (!renderer) {
        // Init renderer.
        const renderer = new CaptionsRenderer(container);
        setRenderer(renderer);
      }

      if (renderer && url) {
        parseResponse(fetch(url)).then((result) => {
          renderer.changeTrack(result);
        });
      }
    }
  }, [container, url, renderer, setRenderer]);

  useEffect(() => {
    setTimestamp(currentTime);
  }, [currentTime]);

  useEffect(() => {
    if (renderer) {
      renderer.currentTime = timestamp;
    }
  }, [renderer, timestamp]);

  useEffect(() => {
    const handleTimeChange = (e) => {
      if (renderer) {
        renderer.currentTime = e.target.currentTime;
      }
      setTimestamp(e.target.currentTime);
    }

    if (media) {
      media.addEventListener('timeupdate', handleTimeChange);
    }

    return () => {
      if (media) {
        media.removeEventListener('timeupdate', handleTimeChange);
      }
    }
  }, [media, renderer]);

  return <div className={'captions'} ref={setContainer}/>
}
