import VideoPlayer from './VideoPlayer';
import AudioPlayer from './AudioPlayer';
import { useMemo } from 'react';
import mediaService from 'features/media/mediaService';

export default function Voiceover({ media, onComplete, toggleFullscreen }) {
  const captions = useMemo(() => {
    return mediaService.selectCaptions(media);
  }, [media]);

  if (media.bundle === 'video_stream' && (media.dash || media.hls)) {
    // Video player has to be re-mounted when media changes.
    return <VideoPlayer key={media.mid} variant={'voiceover'} autoPlay={true}
                        allowFullscreen={!!toggleFullscreen} hls={media.hls}
                        dash={media.dash} onComplete={onComplete}
                        toggleFullscreen={toggleFullscreen} captions={captions}/>;
  }

  if (media.bundle === 'audio_protected') {
    return <AudioPlayer key={media.mid} variant={'voiceover'}
                        src={media?.file?.url} autoPlay={true}
                        onComplete={onComplete}
                        toggleFullscreen={toggleFullscreen} captions={captions}/>;
  }
}
