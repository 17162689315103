import authService from '../auth/authService';

const selectMediaByResolution = (media = [], width, height) => {
  // Select best resolution and aspect ratio.
  let screenWidth = window.innerWidth;
  let screenHeight = window.innerHeight;
  if (width && height) {
    screenWidth = width;
    screenHeight = height;
  }
  const screenRatio = screenWidth / screenHeight;

  media = media.filter((item) => {
    return !!item?.file;
  });

  if (media.length > 1) {
    media.sort((a, b) => {
      const aWidth = a.file.width || 1;
      const aHeight = a.file.height || 1;
      const bWidth = b.file.width || 1;
      const bHeight = b.file.height || 1;
      const aRatio = aWidth / aHeight;
      const bRatio = bWidth / bHeight;
      const ratioDiff = Math.abs(aRatio - screenRatio) - Math.abs(bRatio - screenRatio);

      if (Math.abs(ratioDiff) < 0.01) {
        return Math.abs(aWidth - screenWidth) < Math.abs(bWidth - screenWidth) ? -1 : 1;
      } else {
        return ratioDiff;
      }
    });
  }

  if (media.length > 0) {
    return media[0];
  }

  return false;
};

const preloadMedia = async (media = {}) => {
  if (media.file?.url) {
    try {
      const response = await authService.getClient().request({
        url: media.file.url,
        baseURL: new URL(document.baseURI).origin,
        method: 'GET',
        responseType: 'arraybuffer',
        headers: {
          'Accept': '*/*',
        },
      });

      const file = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      return URL.createObjectURL(file);
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  return false;
};

const selectCaptions = (media, lang = 'en') => {
  if (Array.isArray(media?.captions)) {
    const captions = media.captions.filter(captionsMedia => captionsMedia?.language?.langcode === lang && captionsMedia.file?.url);
    if (captions.length > 0) {
      return {
        'url': captions[0].file.url,
        'lang': captions[0].language?.langcode || lang,
      };
    }
  }

  return false;
};

const mediaService = {
  selectMediaByResolution,
  preloadMedia,
  selectCaptions,
};

export default mediaService;
