import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {handleApiError} from "../auth/authSlice";
import blocksService from "./blockService";

const blocksAdapter = createEntityAdapter({
  selectId: (block) => block.id,
  sortComparer: (a, b) => {
    return a.weight === b.weight ? 0 : (a.weight < b.weight ? -1 : 1);
  },
});

export const blocksSelector = blocksAdapter.getSelectors(state => state.blocks);
export const getRegionBlocks = (state, region) => {
  return blocksSelector.selectAll(state).filter((block) => {
    return block.region === region;
  });
}
export const getRegionState = (state, region) => {
  const regionState = state.blocks.regions[region] || {};
  return {
    isLoading: false,
    isSuccess: false,
    isError: false,
    ...regionState,
  }
}

export const fetchBlocks = createAsyncThunk('blocks/fetchBlocks', async (params, thunkAPI) => {
  try {
    return await blocksService.getBlocks(params);
  } catch (error) {
    return handleApiError(thunkAPI, error);
  }
});

const blocksSlice = createSlice({
  name: 'blocks',
  initialState: blocksAdapter.getInitialState({
    regions: {},
  }),
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlocks.pending, (state, action) => {
        const region = action.meta.arg?.region;
        const uri = action.meta.arg?.uri || '/';

        if (region) {
          state.regions[region] = state.regions[region] || {};
          state.regions[region].isLoading = true;
          state.regions[region].uri = uri;
        }
      })
      .addCase(fetchBlocks.fulfilled, (state, action) => {
        const region = action.meta.arg?.region;
        if (region) {
          const removeIds = [];
          state.ids.forEach(id => {
            const block = state.entities[id];
            if (block.region === region) {
              removeIds.push(id);
            }
          });
          blocksAdapter.removeMany(state, removeIds);

          state.regions[region] = state.regions[region] || {};
          state.regions[region].isLoading = false;
          state.regions[region].isSuccess = true;
          state.regions[region].isError = false;
        }

        blocksAdapter.setMany(state, action.payload);
      })
      .addCase(fetchBlocks.rejected, (state, action) => {
        const region = action.meta.arg?.region;
        if (region) {
          state.regions[region] = state.regions[region] || {};
          state.regions[region].isLoading = false;
          state.regions[region].isSuccess = false;
          state.regions[region].isError = true;
        }
      })
  },
});

export default blocksSlice.reducer;
