import { cn } from 'app/helpers';
import { useEffect, useState } from 'react';
import { parseResponse, renderVTTCueString } from 'media-captions';

export default function Transcript({ url, currentTime }) {
  const classes = cn('transcript');
  const [track, setTrack] = useState();

  useEffect(() => {
    if (url) {
      parseResponse(fetch(url)).then((result) => {
        setTrack(result);
      });
    }
    else {
      setTrack(null);
    }
  }, [url]);

  return <div className={classes()}>
    {track && track.cues && Array.from(track.cues.values()).map((cue, index) => {
      return <span key={index} className={classes('cue', {
        active: typeof currentTime !== 'undefined' && currentTime >= cue.startTime && currentTime <= cue.endTime,
      })}>{renderVTTCueString(cue, currentTime)}</span>
    })}
  </div>
}
